import { Loader } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { changeMail } from '../../api/patch';
import { authorizedGetById, authorizedPutWithId } from '../../fetchFunctions';
import useInput from '../../hooks/useInput';
import { authActions } from '../../store/slice/authSlice';

const YourData = ({ chosenMenuItem }) => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);

	const [isCompany, setIsCompany] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [showTooltip2, setShowTooltip2] = useState(false);
	const [showTooltip3, setShowTooltip3] = useState(false);
	const [detailedUser, setDetailedUser] = useState(null);
	const queryClient = useQueryClient();
	const {
		value: enteredName,
		isTouched: enteredNameIsTouched,
		enteredValueChangeHandler: enteredNameChangeHandler,
		inputBlurHandler: nameBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredLogin,
		isTouched: enteredLoginIsTouched,
		enteredValueChangeHandler: enteredLoginChangeHandler,
		inputBlurHandler: loginBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredAccount,
		isTouched: enteredAccountIsTouched,
		enteredValueChangeHandler: enteredAccountChangeHandler,
		inputBlurHandler: AccountBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredEmail,
		isTouched: enteredEmailIsTouched,
		enteredValueChangeHandler: enteredEmailChangeHandler,
		inputBlurHandler: emailBlurHandler,
	} = useInput((enteredValue) => enteredValue.includes('@'));

	const {
		value: enteredCompany,
		isTouched: enteredCompanyIsTouched,
		enteredValueChangeHandler: enteredCompanyChangeHandler,
		inputBlurHandler: companyBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredVatNumber,
		isTouched: enteredVatNumberIsTouched,
		enteredValueChangeHandler: enteredVatNumberChangeHandler,
		inputBlurHandler: vatNumberBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredPhone,
		isTouched: enteredPhoneIsTouched,
		enteredValueChangeHandler: enteredPhoneChangeHandler,
		inputBlurHandler: phoneBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredAddress,
		isTouched: enteredAddressIsTouched,
		enteredValueChangeHandler: enteredAddressChangeHandler,
		inputBlurHandler: addressBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredZipCode,
		isTouched: enteredZipCodeIsTouched,
		enteredValueChangeHandler: enteredZipCodeChangeHandler,
		inputBlurHandler: zipCodeBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredCity,
		isTouched: enteredCityIsTouched,
		enteredValueChangeHandler: enteredCityChangeHandler,
		inputBlurHandler: cityBlurHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const changeEmailMutation = useMutation(changeMail, {
		onSuccess: () => {
			dispatch(authActions.logOut());
		},
	});

	const changeClientDetailsMutation = useMutation(authorizedPutWithId, {
		onSuccess: () => {
			queryClient.invalidateQueries('clientQuery.data');
			setTimeout(() => {
				changeClientDetailsMutation.reset();
			}, 3000);
		},
	});

	const clientQuery = useQuery(
		['clientQuery.data', user.userId, chosenMenuItem],
		() => authorizedGetById('/publishers', user.userId)
	);

	useEffect(() => {
		setDetailedUser(clientQuery.data);
		setIsCompany(clientQuery.data?.isInvoice);
	}, [clientQuery.data]);

	const changeClientDetailsHandler = (e) => {
		e.preventDefault();
		changeClientDetailsMutation.mutate({
			url: '/publishers',
			id: user.userId,
			data: {
				name: enteredNameIsTouched
					? enteredName.split(' ')[0]
					: clientQuery.data?.name,
				surname: enteredNameIsTouched
					? enteredName.split(' ')[1]
					: clientQuery.data.surname,
				invoiceZipCode: enteredZipCodeIsTouched
					? enteredZipCode
					: clientQuery.data?.invoiceZipCode,
				invoiceCity: enteredCityIsTouched
					? enteredCity
					: clientQuery.data?.invoiceCity,
				nick: enteredLoginIsTouched ? enteredLogin : clientQuery.data?.nick,
				invoiceAddress: enteredAddressIsTouched
					? enteredAddress
					: clientQuery.data?.invoiceAddress,
				invoiceCompanyName: enteredCompanyIsTouched
					? enteredCompany
					: clientQuery.data?.invoiceCompanyName,
				invoiceVatNumber: enteredVatNumberIsTouched
					? enteredVatNumber
					: clientQuery.data?.invoiceVatNumber,
				phoneNumber: enteredPhoneIsTouched
					? enteredPhone
					: clientQuery.data?.phoneNumber,
				invoiceBankAccountNumber: enteredAccountIsTouched
					? enteredAccount
					: clientQuery.data?.invoiceBankAccountNumber,
				isInvoice: isCompany,
			},
		});
		if (enteredEmail && clientQuery.data?.email !== enteredEmail)
			changeEmailMutation.mutate({ id: user.userId, email: enteredEmail });
	};
	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<div className="passwords passwords--your-data">
					<h2 className="main-title main-title--25">Twoje dane</h2>
					<div className="main-text wysiwyg">
						<p>
							Twoje dane rejestracyjno - kontaktowo - rozliczeniowe będziemy
							przechowywać tak długo jak będzie to konieczne dla współpracy z
							Tobą. Masz do nich wzgląd, możesz je uaktualniać na bieżąco.
							Zabezpiecz do nich dostęp poprzez silne hasło. Możesz je w każym
							momencie usunąć, jednak w takiej sytuacji usuniete z serwisu
							zostaną wszystkie Twoje zamieszczenia.
						</p>
					</div>

					{!clientQuery.isLoading && (
						<form
							style={{ marginBottom: 0 }}
							className="form"
							onSubmit={changeClientDetailsHandler}
						>
							<div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="radio-selector-container">
										<div className="form__radio">
											<label className="radio" htmlFor="f-default-customer">
												<input
													checked={!isCompany}
													onClick={() => {
														setIsCompany(false);
													}}
													type="radio"
													name="f-default-customer-company"
													id="f-default-customer"
													className="radio__input js-radio"
												/>
												<span className="radio__text">
													<span className="radio__label">Rachunek</span>
												</span>
											</label>
										</div>

										<div className="form__radio">
											<label className="radio" htmlFor="f-default-company">
												<input
													checked={isCompany}
													onClick={() => {
														setIsCompany(true);
													}}
													type="radio"
													name="f-default-customer-company"
													id="f-default-company"
													className="radio__input js-radio"
												/>
												<span className="radio__text">
													<span className="radio__label">Faktura</span>
												</span>
											</label>
										</div>
									</div>

									<div className="form__group">
										<div className="form-title">
											Dane rejestracyjno-kontaktowe
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													value={
														detailedUser?.isGroup
															? 'Konto grupowe'
															: 'Konto indywidualne'
													}
													type="text"
													placeholder="Rodzaj konta"
													className="form__input required"
													disabled
												/>
											</div>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													defaultValue={detailedUser?.insideId}
													type="email"
													placeholder="Inside Id"
													className="form__input required"
													disabled
													id="f-email"
												/>
											</div>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													disabled
													defaultValue={detailedUser?.nick}
													onClick={() => loginBlurHandler()}
													onChange={(e) => {
														loginBlurHandler();
														enteredLoginChangeHandler(e);
													}}
													type="text"
													placeholder="Login"
													className="form__input required"
													id="f-login"
												/>
												<a
													href="/"
													onMouseEnter={() => setShowTooltip(true)}
													onMouseLeave={() => setShowTooltip(false)}
													className="floating-icon js-tooltip tooltipstered"
												>
													<i className="ico ico-tooltip">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
													{showTooltip && (
														<motion.div
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															transition={{
																default: {
																	duration: 0.3,
																},
															}}
															className="custom-tooltip"
														>
															Login/nazwa podana przy rejestracji.
														</motion.div>
													)}
												</a>
											</div>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													defaultValue={detailedUser?.email}
													onClick={() => emailBlurHandler()}
													onChange={(e) => {
														emailBlurHandler();
														enteredEmailChangeHandler(e);
													}}
													type="email"
													placeholder="Adres e-mail"
													className="form__input required"
													id="f-email"
												/>
												<a
													href="/"
													onMouseEnter={() => setShowTooltip2(true)}
													onMouseLeave={() => setShowTooltip2(false)}
													className="floating-icon js-tooltip tooltipstered"
												>
													<i className="ico ico-tooltip">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
													{showTooltip2 && (
														<motion.div
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															transition={{
																default: {
																	duration: 0.3,
																},
															}}
															className="custom-tooltip"
														>
															Adres e-mail podany podczas rejestracji
														</motion.div>
													)}
												</a>
											</div>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												onClick={() => nameBlurHandler()}
												defaultValue={
													detailedUser?.name?.trim().length > 0
														? detailedUser?.name +
														  ' ' +
														  clientQuery.data?.surname
														: null
												}
												onChange={(e) => {
													nameBlurHandler();
													enteredNameChangeHandler(e);
												}}
												type="text"
												name="f-name"
												placeholder="Imię i nazwisko osoby do kontaktu"
												className="form__input"
												id="f-name"
											/>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												onClick={() => phoneBlurHandler()}
												defaultValue={detailedUser?.phoneNumber}
												onChange={(e) => {
													phoneBlurHandler();
													enteredPhoneChangeHandler(e);
												}}
												type="text"
												name="f-phone"
												placeholder="Numer telefonu"
												className="form__input"
												id="f-phone"
											/>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-lg-5 col-lg-offset-1">
									<div
										className="hidden-invoice-container"
										id="invoiceContainer"
									>
										<div className="form__group">
											<div className="form-title">Dane rozliczeniowe</div>
										</div>

										<div className="form__group">
											<div className="form__field">
												<span
													className="form__info"
													style={{ textAlign: 'left' }}
												>
													Musisz uzupełnić dane rozliczeniowe aby Twoja
													faktura/rachunek zostały przyjęte przez serwis
												</span>
											</div>
										</div>

										<div className="form__group">
											<div className="form__field">
												<div className="input-container">
													<input
														onClick={() => companyBlurHandler()}
														defaultValue={detailedUser?.invoiceCompanyName}
														onChange={(e) => {
															companyBlurHandler();
															enteredCompanyChangeHandler(e);
														}}
														type="text"
														name="f-supplier"
														placeholder="Nazwa zamieszczającego lub pośrednika"
														className="form__input required"
														id="f-supplier"
													/>
													<a
														href="/"
														onMouseEnter={() => setShowTooltip3(true)}
														onMouseLeave={() => setShowTooltip3(false)}
														className="floating-icon js-tooltip tooltipstered"
													>
														<i className="ico ico-tooltip">
															<span className="path1"></span>
															<span className="path2"></span>
														</i>
														{showTooltip3 && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																transition={{
																	default: {
																		duration: 0.3,
																	},
																}}
																className="custom-tooltip"
															>
																Przy wystawianych fakturach/rachunkach musisz
																używać <br />
																tej samej nazwy dostawcy oraz takich samych
																pozostałych danych
															</motion.div>
														)}
													</a>
												</div>
											</div>
										</div>
										<div className="form__group">
											<div className="form__field">
												<input
													onClick={() => addressBlurHandler()}
													defaultValue={detailedUser?.invoiceAddress}
													onChange={(e) => {
														addressBlurHandler();
														enteredAddressChangeHandler(e);
													}}
													type="text"
													name="f-street"
													placeholder="Ulica i nr budynku"
													className="form__input"
													id="f-street"
												/>
											</div>
										</div>
										<div className="form__group">
											<div className="input-wrapper">
												<div className="form__field">
													<input
														onClick={() => zipCodeBlurHandler()}
														defaultValue={detailedUser?.invoiceZipCode}
														onChange={(e) => enteredZipCodeChangeHandler(e)}
														type="text"
														name="f-postal"
														placeholder="Kod pocztowy"
														className="form__input"
														id="f-postal"
													/>
												</div>
												<div className="form__field">
													<input
														onClick={() => cityBlurHandler()}
														defaultValue={detailedUser?.invoiceCity}
														onChange={(e) => enteredCityChangeHandler(e)}
														type="text"
														name="f-city"
														placeholder="Miasto"
														className="form__input"
														id="f-city"
													/>
												</div>
											</div>
										</div>
										{isCompany && (
											<motion.div
												initial={{ opacity: 0, y: 5 }}
												animate={{ opacity: 1, y: 0 }}
												transition={{
													default: {
														duration: 0.3,
													},
												}}
												className="form__group"
											>
												<div className="form__field">
													<input
														maxLength={10}
														onClick={() => vatNumberBlurHandler()}
														onChange={(e) => enteredVatNumberChangeHandler(e)}
														defaultValue={detailedUser?.invoiceVatNumber}
														type="text"
														name="f-nip"
														placeholder="NIP"
														className="form__input required"
														id="f-nip"
													/>
													<span
														style={{ textAlign: 'left' }}
														className="form__info"
													>
														Format XXXXXXXXXX
													</span>
												</div>
											</motion.div>
										)}

										<div className="form__group">
											<div className="form__field">
												<input
													onClick={() => AccountBlurHandler()}
													onChange={(e) => enteredAccountChangeHandler(e)}
													type="text"
													name="f-bank"
													defaultValue={detailedUser?.invoiceBankAccountNumber}
													placeholder="Nr konta bankowego do przelewów"
													className="form__input required"
													id="f-bank"
												/>
												<span
													style={{ textAlign: 'left' }}
													className="form__info"
												>
													Format XX XXXX XXXX XXXX XXXX XXXX XXXX
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form__actions">
										<button type="submit" className="btn btn--block">
											{changeClientDetailsMutation.isLoading ? (
												<div>
													<Loader size={14} />
												</div>
											) : (
												'Zapisz zmiany'
											)}
										</button>
										{changeClientDetailsMutation.isSuccess && (
											<motion.p
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												style={{
													color: 'green',
													marginBottom: 0,
													textAlign: 'center',
													marginTop: 10,
												}}
											>
												Twoje zmiany zostały zapisane!
											</motion.p>
										)}
									</div>
								</div>
							</div>
						</form>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default YourData;
