import React, { useEffect, useMemo, useState } from 'react';
import {
	Select,
	FileInput,
	LoadingOverlay,
	Tooltip,
	TextInput,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { motion } from 'framer-motion';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { formatDateForPresentation } from '../../utils';
import { postPublisherInvoice } from '../../api/post';
import { authorizedFetch } from '../../api/authorizedFetch';
import 'dayjs/locale/pl';
import { authorizedGetById } from '../../fetchFunctions';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const DOCUMENT_TYPES = [
	{
		value: '1',
		label: 'Faktura',
	},
	{
		value: '2',
		label: 'Korekta faktury',
	},
	{
		value: '3',
		label: 'Rachunek',
	},
	{
		value: '4',
		label: 'Korekta rachunku',
	},
];

const AddRecipe = ({ setChosenMenuItem }) => {
	const [form, setForm] = useState(blankForm());

	const [fileBase, setFileBase] = useState({
		base: null,
		type: null,
	});

	useEffect(() => {
		if (!form.file) return;

		const reader = new FileReader();
		reader.readAsDataURL(form.file);
		reader.onload = function () {
			setFileBase({
				base: reader.result,
				type: form.file.type,
			});
		};
	}, [form.file]);

	const { user } = useSelector((store) => store.auth);

	const userQuery = useQuery(
		['detailedClient', user.userId],
		() => authorizedGetById('/publishers', user.userId),
		{
			staleTime: 1000 * 60 * 5,
		}
	);

	const [showTooltip, setShowTooltip] = useState(false);
	const [showTooltip2, setShowTooltip2] = useState(false);
	const [dueAmmount, setDueAmmount] = useState(0);

	const createMutation = useMutation(postPublisherInvoice, {
		onSettled: (_) => {
			setForm(blankForm());
		},
	});

	const duesQuery = useQuery(
		['dues', user.userId],
		() => authorizedFetch(`publisher_dues?publisher=${user.userId}`),
		{
			placeholderData: [],
		}
	);

	const changeForm = (key, val) => {
		if (key !== 'publisherDue') {
			setForm((prev) => ({ ...prev, [key]: val }));
			return;
		}

		setForm((prev) => {
			const grossAmount =
				duesQuery.data?.find((d) => d.id === val).amount / 100;
			setDueAmmount(grossAmount);

			return { ...prev, grossAmount, publisherDue: val };
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		createMutation.mutate({
			...form,
			grossAmount: Math.round(Number(form.grossAmount) * 100),
			documentType: Number(form.documentType),
		});
	};

	useEffect(() => {
		changeForm('documentType', null);
	}, [form.publisherDue]);

	const selectedDue = useMemo(
		() => duesQuery.data?.find((due) => due.id === form.publisherDue),
		[form.publisherDue, duesQuery.data]
	);

	const availableDocumentTypes = useMemo(
		() =>
			DOCUMENT_TYPES.filter((type) => {
				if (!selectedDue) return false;

				if (['1', '3'].includes(type.value))
					return !selectedDue.publisherInvoices.length;

				if (type.value === '2')
					return selectedDue.publisherInvoices.some(
						(invoice) => invoice.documentType === 1 && invoice.status === 3
					);

				if (type.value === '4')
					return selectedDue.publisherInvoices.some(
						(invoice) => invoice.documentType === 3 && invoice.status === 3
					);
			}),
		[selectedDue]
	);

	return (
		<div className="white-box white-box--library">
			<div className="white-box__inner" style={{ position: 'relative' }}>
				<LoadingOverlay
					visible={createMutation.isLoading || userQuery.isLoading}
				/>
				<div className="your-data">
					<h2 className="main-title main-title--25">Dodaj dokument księgowy</h2>
					<div className="main-text wysiwyg mb-30">
						<p>
							Dodając fakturę/rachunek musisz poniższy formularz wypełnić
							zgodnie z danymi podanymi na dokumencie. Jeśli popełnisz błąd Twój
							dokument/rozliczenie nie zostanią przyjęte.
						</p>
					</div>

					{!!userQuery.data?.invoiceBankAccountNumber && (
						<form className="form js-validate" onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-md-4">
									<div className="form__group">
										<div className="form__field">
											<div className="select">
												<div className="input-container">
													<Select
														disabled={!duesQuery.data.length}
														required
														className={'f-sort'}
														onChange={(val) => {
															changeForm('publisherDue', val);
														}}
														placeholder="Wybierz okres rozliczeniowy"
														data={duesQuery.data.map((due) => ({
															label: formatDateForPresentation(due.dueDate),
															value: due.id,
														}))}
														rightSection={<IconChevronDown size={10} />}
														rightSectionWidth={27}
														styles={(theme) => ({
															rightSection: {
																marginRight: 20,
																pointerEvents: 'none',
																'& svg': {
																	strokeWidth: 1,
																},
															},
															input: {
																backgroundColor: '#fff',
																border: '2px solid #BCD3E5',
																borderRadius: 0,
																transition: 'all 0.3s ease-out 0s',
																height: 57,
															},
															item: {
																'&[data-selected]': {
																	backgroundColor: '#d0dae2',
																	'&, &:hover': {
																		backgroundColor: '#d0dae2',
																		color: '#fff',
																	},
																},
																'&[data-hovered]': {
																	backgroundColor: '#364a59',
																	color: '#fff',
																},
															},
														})}
													/>
													{!duesQuery.isPlaceholderData &&
														!duesQuery.data.length && (
															<a
																href="/"
																className="floating-icon js-tooltip tooltipstered"
															>
																<Tooltip label="Pole stanie się aktywne po zakończeniu okresu rozliczeniowego">
																	<i className="ico ico-tooltip">
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</i>
																</Tooltip>
															</a>
														)}
												</div>
											</div>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<div className="select">
												<Select
													required
													className={'f-sort'}
													value={form.documentType}
													onChange={(val) => {
														changeForm('documentType', val);
													}}
													placeholder="Wybierz rodzaj dokumentu"
													data={availableDocumentTypes}
													rightSection={<IconChevronDown size={10} />}
													rightSectionWidth={27}
													styles={(theme) => ({
														rightSection: {
															marginRight: 20,
															pointerEvents: 'none',
															'& svg': {
																strokeWidth: 1,
															},
														},
														input: {
															backgroundColor: '#fff',
															border: '2px solid #BCD3E5',
															borderRadius: 0,
															transition: 'all 0.3s ease-out 0s',
															height: 57,
														},
														item: {
															'&[data-selected]': {
																backgroundColor: '#d0dae2',
																'&, &:hover': {
																	backgroundColor: '#d0dae2',
																	color: '#fff',
																},
															},
															'&[data-hovered]': {
																backgroundColor: '#364a59',
																color: '#fff',
															},
														},
													})}
													disabled={!form.publisherDue}
												/>
											</div>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												required
												value={userQuery.data.insideId}
												type="text"
												placeholder="ID użytkownika"
												className="form__input"
												readOnly
											/>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												required
												value={form.invoiceNumber}
												onChange={({ target }) =>
													changeForm(
														'invoiceNumber',
														target.value.replace(/[^0-9/]/g, '')
													)
												}
												type="text"
												placeholder="Nr dokumentu"
												className="form__input"
											/>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<TextInput
													required
													error={
														form.publisherDue &&
														form.grossAmount !== dueAmmount &&
														'Podana kwota jest niezgodna z wybranym rozliczeniem!'
													}
													type="number"
													placeholder="Kwota brutto"
													value={form.grossAmount}
													onChange={({ target }) =>
														changeForm('grossAmount', Number(target.value))
													}
												/>
												<a
													href="/"
													onMouseEnter={() => setShowTooltip2(true)}
													onMouseLeave={() => setShowTooltip2(false)}
													className="floating-icon js-tooltip tooltipstered"
												>
													<i className="ico ico-tooltip">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
													{showTooltip2 && (
														<motion.div
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															transition={{
																default: {
																	duration: 0.3,
																},
															}}
															className="custom-tooltip"
														>
															Kwota brutto, na którą wystawiasz fakturę oraz
															stawka podatku VAT muszą być takie same jak w
															wiadomości e-mail, którą do Ciebie wysłaliśmy.
															<br /> Jeżeli pojawią się różnice to Twój dokument
															rozliczeniowy nie zostanie przyjęty.
														</motion.div>
													)}
												</a>
											</div>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<TextInput
													required
													type="text"
													placeholder="Opis"
													value={form.description}
													onChange={(e) =>
														changeForm('description', e.target.value)
													}
												/>
											</div>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													required
													type="text"
													placeholder="Nazwa dostawcy"
													className="form__input required"
													value={form.providerName}
													onChange={(e) =>
														changeForm('providerName', e.target.value)
													}
												/>
												<a
													href="/"
													onMouseEnter={() => setShowTooltip(true)}
													onMouseLeave={() => setShowTooltip(false)}
													className="floating-icon js-tooltip tooltipstered"
												>
													<i className="ico ico-tooltip">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
													{showTooltip && (
														<motion.div
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															transition={{
																default: {
																	duration: 0.3,
																},
															}}
															className="custom-tooltip"
														>
															Twoja nazwa dostawcy musi być identyczna z tą na
															wystawionym dokumencie oraz z tą podaną w twoich
															danych rozliczeniowych.
														</motion.div>
													)}
												</a>
											</div>
										</div>
									</div>
									<div
										className="form__group"
										style={{
											display: 'flex',
											gap: 16,
										}}
									>
										<div className="form__field">
											<input
												required
												type="text"
												placeholder="Imię"
												className="form__input"
												value={userQuery.data?.name}
												readOnly
											/>
										</div>
										<div className="form__field">
											<input
												required
												type="text"
												placeholder="Nazwisko"
												className="form__input"
												value={userQuery.data?.surname}
												readOnly
											/>
										</div>
									</div>
									<div
										className="form__group"
										style={{
											display: 'flex',
											gap: 16,
										}}
									>
										<div className="form__field">
											<input
												required
												type="text"
												placeholder="Kod pocztowy"
												className="form__input"
												value={userQuery.data?.invoiceZipCode}
												readOnly
											/>
										</div>
										<div className="form__field">
											<input
												required
												type="text"
												placeholder="Miasto"
												className="form__input"
												value={userQuery.data?.invoiceCity}
												readOnly
											/>
										</div>
									</div>
									<div
										className="form__group"
										style={{
											display: 'flex',
											gap: 16,
										}}
									>
										<div className="form__field">
											<input
												required
												type="text"
												placeholder="Adres"
												className="form__input"
												value={userQuery.data?.invoiceAddress}
												readOnly
											/>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<input
												required
												value={userQuery.data.invoiceVatNumber}
												type="text"
												placeholder="NIP"
												className="form__input"
												readOnly
											/>
										</div>
									</div>

									<div className="form__group">
										<FileInput
											required
											onChange={(f) => {
												if (f) changeForm('file', f);
											}}
											placeholder={'+ Dodaj Dokument (Format PDF lub JPG)'}
											accept="image/png,image/jpeg,application/pdf"
											styles={(theme) => ({
												rightSection: {
													marginRight: 20,
													'& svg': {
														strokeWidth: 1,
													},
												},
												placeholder: {
													color: '#000 !important',
													fontSize: 14,
													fontWeight: 600,
													fontFamily: `"Open Sans", sans-serif`,
												},
												input: {
													backgroundColor: '#E6F0F7',
													border: '2px solid #BCD3E5',
													borderRadius: 0,
													transition: 'all 0.3s ease-out 0s',
													height: 57,
													textAlign: 'center',
												},
												item: {
													'&[data-selected]': {
														backgroundColor: '#d0dae2',
														'&, &:hover': {
															backgroundColor: '#d0dae2',
															color: '#fff',
														},
													},

													'&[data-hovered]': {
														backgroundColor: '#364a59',
														color: '#fff',
													},
												},
											})}
										/>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												required
												value={userQuery.data.invoiceBankAccountNumber}
												type="text"
												placeholder="Nr konta"
												className="form__input"
												readOnly
											/>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												required
												value={selectedDue?.publisherInvoices
													.map((i) => i.insideId)
													.join(', ')}
												readOnly
												type="text"
												placeholder="Nr zunifikowany"
												className="form__input"
											/>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<span
												className="form__info"
												style={{ textAlign: 'left' }}
											>
												Wystawiając nam fakturę/rachunek akceptujesz i
												potwierdzasz tym samym warunki wspólpracy z nami w tym
												warunki dokonanych udostępnięń, zaproponowane kwoty
												bazowe dla pobrań, przyznany Ci procent od pobrań,
												warunki korzystania z serwisu, ogolne warunki dla
												zamieszczających i prowadzoną przez nas politykę
												prywatności.
											</span>
										</div>
									</div>
								</div>

								<div className="col-md-8">
									{!!fileBase.type && fileBase.type !== 'application/pdf' && (
										<div
											style={{ height: '750px', overflow: 'hidden' }}
											className="mb-20"
										>
											<img src={fileBase.base} />
										</div>
									)}
									<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
										{!!fileBase.type && fileBase.type === 'application/pdf' && (
											<div className="mb-20">
												<Viewer fileUrl={fileBase.base} defaultScale={0} />
											</div>
										)}
									</Worker>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form__actions">
										<button
											type="submit"
											className="btn btn--block"
											disabled={
												!form.file || !form.publisherDue || !form.documentType
											}
										>
											<span>Wyślij i zakończ dostarczanie dokumentu</span>
										</button>
									</div>
									{createMutation.isSuccess && (
										<p
											style={{
												color: 'green',
												textAlign: 'center',
												marginTop: 20,
											}}
										>
											Dziękujemy za dodanie dokumentu.
										</p>
									)}
									{createMutation.isError && (
										<p
											style={{
												color: 'red',
												textAlign: 'center',
												marginTop: 20,
											}}
										>
											Coś poszło nie tak!
										</p>
									)}
								</div>
							</div>
						</form>
					)}
					{!userQuery.data?.invoiceBankAccountNumber &&
						!userQuery.isLoading && (
							<div className="main-text wysiwyg mb-30">
								<p>
									Aby dodać dokument rozliczeniowy musisz uzupełnić numer konta.
									Przejdź do{' '}
									<a
										href="/"
										onClick={(e) => {
											e.preventDefault();
											setChosenMenuItem('Twoje dane');
										}}
									>
										edycji danych
									</a>
								</p>
							</div>
						)}
				</div>
			</div>
		</div>
	);
};

export default AddRecipe;

function blankForm() {
	return {
		documentType: null,
		file: null,
		invoiceNumber: '',
		invoiceDate: null,
		invoiceCompanyName: '',
		publisherDue: null,
		grossAmount: 0,
		VATRate: undefined,
		description: '',
	};
}
